<template>
	<div>
		<s-crud
			title="Lugares de muestra selección"
			:config="config"
			add
			edit
			remove
			@save="save($event)"
			:filter="filter"
		>
			<template scope="props">
				<v-container>
					<v-row>
						<v-col cols="12" lg="4" md="6" sm="12" class="s-col-form">
							<s-text
								v-model="props.item.SlsAbbreviation"
								label="Abreviación"
							></s-text>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12" class="s-col-form">
							<s-text
								v-model="props.item.SlsName"
								label="Descripción"
							></s-text>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12" class="s-col-form">
							<s-text
								v-model="props.item.SlsDisinfectant"
								label="Desinfectante"
							></s-text>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" lg="4" md="6" sm="12" class="s-col-form">
							<s-text
								v-model="props.item.SlsDetergent"
								label="Detergente"
							></s-text>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12" class="s-col-form">
							<s-text
								v-model="props.item.SlsChlorineInmersionTime"
								label="Tiempo Inmersión Cloro"
							></s-text>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12" class="s-col-form">
							<s-text
								v-model="props.item.SlsTsunamiInmersionTime"
								label="Tiempo Inm. Tsunami"
							></s-text>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<!-- <template v-slot:SplStatus="{row}">
				<v-chip 
					x-small
					:color="row.SplStatusColor"
				>
					{{row.SplStatusName}}
				</v-chip>
			</template> -->
		</s-crud>
	</div>
</template>

<script>
	import _sSamplingLocationSelectionService from "@/services/FrozenProduction/SamplingLocationSelectionService.js";
	export default {
		props: {
			TypeCultive: {
				type: Number,
				default: 0
			},
		},

		watch: {
			TypeCultive() {
				this.Initialize();
			}
		},
		
		data() {
			return {
				filter: {TypeCultive: 0},
				config: {
					service: _sSamplingLocationSelectionService,
					model: {
						SlsID: "ID",
						SplStatus: "number"
					},
					headers: [
						{ text: "ID", value: "SlsID" },
						{ text: "Abre.", value: "SlsAbbreviation" },
						{ text: "Lugar", value: "SlsName" },
						{ text: "Desinfectante", value: "SlsDisinfectant" },
						{ text: "Detergente", value: "SlsDetergent" },
						{ text: "Tiempo Inm. Cloro", value: "SlsChlorineInmersionTime" },
						{ text: "Tiempo Inm. Tsunami", value: "SlsTsunamiInmersionTime" },
					],
				},
			}
		},

		methods: {
			Initialize()
			{
				this.filter = { TypeCultive: this.TypeCultive }
			},

			save(item){
				if(item.SlsName == ""){
					this.$fun.alert("Ingrese descripción");
					return;
				}
				item.TypeCultive = this.TypeCultive;
				item.save();
			}
		},

		created () {
			
		},
	}

</script>
